import httpService from "./http.service";

export const refundTransaction = async (transactionId) => {
  return httpService.apiRequest("post", "/api/transaction/refund", {
    transactionId,
  });
};
export const cancelTransaction = async (transactionId) => {
  return httpService.apiRequest("post", "/api/transaction/cancel", {
    transactionId,
  });
};
export const chargeTransaction = async (transactionId) => {
  return httpService.apiRequest("post", "/api/transaction/charge", {
    transactionId,
  });
};

export const getChargedContainersTransactions = async (
  searchTerm = "",
  pageNumber = 0,
  pageSize = 10
) => {
  return httpService.apiRequest(
    "post",
    "/api/transaction/getContainersTransactions",
    { searchTerm, pageNumber, pageSize }
  );
};

export const getTransactionsStats = async (filter) => {
  return httpService.apiRequest(
    "post",
    "/api/transaction/getTransactionStats",
    { filter }
  );
};
