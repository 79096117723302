import React, { useState } from "react";
import Button from "./../../../button";
import t from "./../../../../_config/constants";
import moment from "moment";
import { getStatistics } from "../../../../_services/statistics.service";
import { exportToCSVTabs } from "../../../../_utils/exportToCSVtabs";
import toastService from "../../../../_services/toast.service";
const DownloadLogsStatistics = React.memo(
  ({
    disabled,
    filter = { period: "daily", startOfWeek: "", month: "", year: "" },
  }) => {
    const [loadingCSV, setLoadingCSV] = useState(false);
    const handleDownload = async () => {
      setLoadingCSV(true);
      document.body.style.cursor = "wait";
      const { period, startOfWeek, month, year } = filter;
      if (
        (period === "daily" && ((!month && month !== 0) || !year)) ||
        (period === "weekly" && !startOfWeek)
      ) {
        toastService.show("warning", "Please select date.");
        setLoadingCSV(false);
        document.body.style.cursor = "auto";
        return;
      }
      const fileName =
        "Store Activity Statistics - " +
        (period === "daily"
          ? moment(new Date(year, month).getTime()).format("MMMM YYYY")
          : moment(new Date(startOfWeek).getTime()).format("MM.DD.YY") +
            "-" +
            moment(
              new Date(startOfWeek + 6 * 24 * 60 * 60 * 1000).getTime()
            ).format("MM.DD.YY"));

      const result = await getStatistics({
        mode: "get-store-logs-statistics",
        filter,
      });
      if (Object.keys(result).length) {
        exportToCSVTabs(result, fileName);
      } else {
        toastService.show("warning", "No activity logs found");
      }
      setLoadingCSV(false);
      document.body.style.cursor = "auto";
    };

    return (
      <div
        title={
          !["daily", "weekly"].includes(filter.period)
            ? "Available for daily and weekly activity"
            : ""
        }
      >
        <Button
          disabled={
            disabled ||
            loadingCSV ||
            !["daily", "weekly"].includes(filter.period)
          }
          label={"Download Logs"}
          btnClass="btnWhiteBackground"
          onClick={handleDownload}
        />
      </div>
    );
  }
);

export default DownloadLogsStatistics;
