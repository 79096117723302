import React, { useState, useEffect } from "react";
import styles from "./singleLocation.module.scss";

import { useDispatch } from "react-redux";

import Input from "../../../input";
import Button from "../../../button";
import ConfirmModal from "../../../modal/confirmModal";
import QrGenerator from "./../../qrCode/index";
import FormModal from "../../../modal/formModal";
import DropoffForm from "./../form";
import Textarea from "../../../textarea";
import LocationDetails from "./details";

import { mainActions } from "../../../../_store/actions/main.actions";
import { isObjectEmpty } from "../../../../_utils/utils";
import t from "../../../../_config/constants";
import Report from "../../report";
import { modalActions } from "../../../../_store/actions/modal.actions";
import OperatorEmails from "./operatorEmails";

const SingleLocation = ({ selectedLocationId, onUpdate }) => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);
  const [dropoffLocation, setDropoffLocation] = useState({});
  const [showWorkingTime, setShowWorkingTime] = useState(false);
  const [step, setStep] = useState("location");
  const [refresh, setRefresh] = useState(true);
  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();

  const getSelectedLocation = async (id) => {
    const response = await dispatch(
      mainActions.run("data", "location", "id", id)
    );
    setDropoffLocation(response);
  };

  useEffect(() => {
    if (selectedLocationId) {
      getSelectedLocation(selectedLocationId);
    }
  }, [selectedLocationId, refresh]);

  const openDeleteModal = async (e) => {
    e.preventDefault();
    setShowModalDelete(true);
    setModalData(dropoffLocation);
  };

  const handleDelete = async (answer, data) => {
    if (answer) {
      await dispatch(
        mainActions.run(
          "data",
          "location",
          "delete",
          { _id: dropoffLocation._id, type: "location" },
          { message: t.dropoff.text_successfully_deleted_location }
        )
      );
      onUpdate();
      setDropoffLocation({});
      setShowModalDelete(false);
      setModalData({});
    } else {
      setShowModalDelete(false);
    }
  };

  const handleEdit = () => {
    setShowEditForm(true);
  };

  const closeEditDropoffForm = () => {
    setShowEditForm(false);
    getSelectedLocation(selectedLocationId);
    onUpdate();
  };

  const copyJSONConfig = () => {
    const settings = {
      srs: {
        locationId: dropoffLocation._id,
        autoNavigate: false,
        locationName: dropoffLocation.name,
        containerAssignment: dropoffLocation.containerAssignment || "",
      },
      checkout: {
        email: "",
        password: "",
        autoNavigate: false,
        containerAssignment: [],
        userAssignment: [],
      },
      deviceId: "",
      deviceType: "SRS",
      deviceAlias: "",
      environment: process.env.REACT_APP_ENVIROMENT || "development",
    };
    const jsonString = JSON.stringify(settings, null, 2);
    navigator.clipboard.writeText(jsonString);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 650);
  };
  const closeForm = () => {
    setShowEditForm(false);
  };

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const workingTime = days.map((day) => {
    if (
      !isObjectEmpty(dropoffLocation) &&
      !isObjectEmpty(dropoffLocation.workingTime)
    ) {
      if (
        dropoffLocation?.workingTime[day.toLocaleLowerCase()]?.from &&
        dropoffLocation?.workingTime[day.toLocaleLowerCase()]?.to
      ) {
        return (
          <p key={day}>
            {day}
            <span>
              {dropoffLocation?.workingTime[day.toLocaleLowerCase()]?.from}-
              {dropoffLocation?.workingTime[day.toLocaleLowerCase()]?.to}{" "}
            </span>
          </p>
        );
      } else {
        return (
          <p key={day}>
            {day}
            <span>{t.dropoff.text_closed}</span>
          </p>
        );
      }
    }
  });

  return (
    <div className={styles.wrapper}>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          modalData={modalData}
          question={t.dropoff.text_modal_delete_location}
        />
      )}
      {showEditForm && (
        <FormModal
          show={showEditForm}
          handleClose={closeForm}
          content={
            <DropoffForm
              editLocation={dropoffLocation}
              handleClose={closeEditDropoffForm}
            />
          }
          size="xlg"
        />
      )}
      {isObjectEmpty(dropoffLocation) && (
        <div className={styles.noData}>
          <span>{t.dropoff.text_select_guideline}</span>
        </div>
      )}
      {!isObjectEmpty(dropoffLocation) && step === "location" && (
        <div>
          <div className={styles.options}>
            <Button
              btnClass={"btnWhiteBackground"}
              label={t.button.text_edit}
              onClick={handleEdit}
            />
            <Button
              btnClass={"btnWhiteBackground"}
              label={t.button.text_delete}
              onClick={(e) => openDeleteModal(e, dropoffLocation)}
            />
          </div>
          {dropoffLocation.isDraft && (
            <div className={styles.draft}>[Draft]</div>
          )}
          <div className={styles.qrCode}>
            {dropoffLocation?._id &&
              dropoffLocation?.name &&
              dropoffLocation?.location &&
              dropoffLocation?.coordinates && (
                <QrGenerator
                  id={dropoffLocation._id}
                  name={dropoffLocation.name}
                  location={dropoffLocation.location}
                  coordinates={dropoffLocation.coordinates}
                />
              )}
          </div>
          <div className={styles.content}>
            <Input
              label={t.dropoff.text_input_name}
              name="name"
              inputClass="inputForm"
              type="text"
              disabled={true}
              value={dropoffLocation.name}
            />
            <Input
              label={t.dropoff.text_input_address}
              name="location"
              inputClass="inputForm"
              type="text"
              disabled={true}
              value={dropoffLocation.location}
            />
            <Input
              label={"Location ID"}
              name="_id"
              inputClass="inputForm"
              type="text"
              disabled={true}
              value={dropoffLocation._id}
            />
            <Textarea
              label={t.dropoff.text_input_description}
              name="description"
              textareaClass="textareaWhite"
              type="text"
              disabled={true}
              value={dropoffLocation.description}
            />
            {dropoffLocation.isRestaurant && (
              <div className={styles.cleaningQr}>
                <Input
                  label={t.dropoff.text_cleaning_settings}
                  name="isCleaning"
                  inputClass="inputForm"
                  type="text"
                  disabled={true}
                  value={
                    dropoffLocation.isCleaning
                      ? t.dropoff.text_cleaning_by_themselves
                      : t.dropoff.text_cleaning_service
                  }
                />
              </div>
            )}
            <div className={styles.workingTime}>
              <div
                className={styles.title}
                onClick={() => setShowWorkingTime(!showWorkingTime)}
              >
                <p>{t.dropoff.text_working_hours}</p>
                <p>{showWorkingTime ? "-" : "+"}</p>
              </div>
              {showWorkingTime && (
                <div className={styles.content}>{workingTime}</div>
              )}
            </div>
            {!dropoffLocation.isDraft && (
              <div className={styles.buttons}>
                <Button
                  btnClass={"btnWhiteBackground"}
                  label={copied ? "Copied!" : "Copy JSON Config"}
                  onClick={() => copyJSONConfig()}
                />
                <Button
                  label={"View Operator Emails"}
                  btnClass={"btnWhiteBackground"}
                  onClick={() => {
                    dispatch(
                      modalActions.openModal(
                        <OperatorEmails
                          currentEmails={dropoffLocation.operatorEmails}
                          locationId={dropoffLocation._id}
                          locationName={dropoffLocation.name}
                          setRefresh={setRefresh}
                        />
                      )
                    );
                  }}
                />
                <Report dropoffLocation={dropoffLocation} />
                <Button
                  btnClass={"btnNormalCasal"}
                  label="View Details"
                  onClick={() => setStep("details")}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {!isObjectEmpty(dropoffLocation) && step === "details" && (
        <LocationDetails dropoffLocation={dropoffLocation} setStep={setStep} />
      )}
    </div>
  );
};

export default SingleLocation;
