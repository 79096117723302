import React, { useEffect, useState } from "react";
import styles from "./container.module.scss";
import Loading from "../../../../loading";
import { ReactComponent as HelpIcon } from "../../../../../_assets/icons/icon _help.svg";

const ContainerBox = ({
  name,
  iconURL,
  amount,
  type = "large",
  tooltip = "",
}) => {
  const [hoveredTooltipId, setHoveredTooltipId] = useState(null);

  return (
    <div className={`${styles[`wrapper_${type}`]}`}>
      <div className={styles.header}>
        <p>{name}</p>
        {tooltip && (
          <div
            className={styles.help}
            onMouseEnter={() => setHoveredTooltipId(1)}
            onMouseLeave={() => setHoveredTooltipId(null)}
          >
            <HelpIcon />
          </div>
        )}
        {hoveredTooltipId == 1 && (
          <div className={styles.tooltip_left}>{tooltip}</div>
        )}
        {iconURL && (
          <div className={styles.imgContainer}>
            <img src={iconURL} />
          </div>
        )}
      </div>
      {!amount && <Loading height={50} width={50} />}
      <div className={styles.content}>{amount}</div>
    </div>
  );
};

export default ContainerBox;
