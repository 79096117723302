import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import Loading from "../../loading";
import { qrRedirectUrl } from "../../../environment";
const QrGenerator = ({ id, batchId }) => {
  const [qr, setQr] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id && batchId) generateQr(id, batchId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const generateQr = async (id, batchId) => {
    let response = "";
    try {
      response = await QRCode.toDataURL(
        `${qrRedirectUrl}?container=${id + ""}&batch=${batchId}`
      );
    } catch (error) {}
    setQr(response);
    setLoading(false);
  };

  return (
    <>
      {!loading && qr !== "" && <img src={qr} alt="qr_code" />}
      {loading && !qr && <Loading width={25} height={25} />}
    </>
  );
};
export default QrGenerator;
