import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./transactionDetails.module.scss";
import {
  capitalizeWord,
  getPaymentType,
  getTransactionsType,
  getUserName,
} from "../../../../_utils/utils";
import moment from "moment";
import toastService from "../../../../_services/toast.service";
import { modalActions } from "../../../../_store/actions/modal.actions";
import {
  refundTransaction,
  cancelTransaction,
} from "../../../../_services/transaction.service";
import Button from "../../../button";
const TransactionDetails = ({ transaction, setRefresh }) => {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState("");
  const [confirming, setConfirming] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (transaction) {
      let action;
      if (
        transaction.transactionType === "REFUND" ||
        transaction.status === "REFUNDED"
      ) {
        setActionType("");
        return;
      }
      switch (transaction.status) {
        case "SUCCESSFULL":
          action = "Refund";
          break;
        case "SUCCESSFUL":
          action = "Refund";
          break;
        case "CHARGED":
          action = "Refund";
          break;
        case "PENDING":
          if (
            (transaction.paymentType === "CARD" &&
              transaction.transactionType !== "PRE_AUTH_PAYMENT_INTENT") ||
            transaction.charged
          ) {
            action = "";
          } else {
            action = "Cancel";
          }
          break;
        default:
          action = "";
      }
      setActionType(action);
    }
  }, [transaction]);
  const handleAction = async () => {
    setLoading(true);
    try {
      switch (actionType) {
        case "Refund":
          await refundTransaction(transaction._id);
          break;
        case "Cancel":
          await cancelTransaction(transaction._id);
          break;
      }
      setRefresh((prev) => !prev);
      toastService.show("success", `Transaction successfully processed.`);
      dispatch(modalActions.closeModal());
    } catch (error) {
    } finally {
      setLoading(false);
      setConfirming(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <h3> {getTransactionsType(transaction.transactionType)}</h3>
      <div className={styles.details}>
        <div className={styles.row}>
          <p className={styles.header}>
            {transaction.user?.type === "restaurant" ? "Store" : "Reuser"}
          </p>
          <p>{getUserName(transaction)}</p>
        </div>
        <div className={styles.row}>
          <p className={styles.header}>Status</p>
          <p>{capitalizeWord(transaction.status)}</p>
        </div>
        {transaction.purchase_failed_reason && (
          <div className={styles.row}>
            <p className={styles.header}>Reason</p>
            <p style={{ "padding-left": "25px" }}>
              {transaction.purchase_failed_reason}
            </p>
          </div>
        )}
        <div className={styles.row}>
          <p className={styles.header}>Payment Type</p>
          <p>{getPaymentType(transaction.paymentType)}</p>
        </div>
        <div className={styles.row}>
          <p className={styles.header}>Amount</p>
          <p>
            $
            {transaction.amount && transaction.amount != "NaN"
              ? transaction.amount
              : "0.00"}{" "}
            {transaction.currency?.toUpperCase()}
          </p>
        </div>
        <div className={styles.row}>
          <p className={styles.header}>Date</p>
          <p> {moment(transaction.createdAt).format("MM/DD/YYYY")}</p>
        </div>
      </div>
      {confirming ? (
        <div className={styles.confirmationRow}>
          <p>Are you sure you want to proceed with this action?</p>
          <div className={styles.confirmationButtons}>
            <Button
              btnClass="btnNormalCasalLight"
              label={loading ? "Processing..." : "Yes"}
              onClick={handleAction}
              disabled={loading}
            />
            <Button
              btnClass="btnNormal"
              label={"No"}
              onClick={() => setConfirming(false)}
              disabled={loading}
            />
          </div>
        </div>
      ) : !actionType ? null : (
        <button
          onClick={() => setConfirming(true)}
          className={styles.actionButton}
        >
          {actionType} Transaction
        </button>
      )}
    </div>
  );
};
export default TransactionDetails;
