import React from "react";
import styles from "./containers.module.scss";
import ContainerBox from "./containerBox";

const ContainerStats = ({ data = {} }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <ContainerBox name={"Assigned Containers"} amount={data.inUse} />
        <ContainerBox
          name={"Containers in Inventory"}
          amount={data.inStoreInventory}
        />
        <ContainerBox
          name={"Unreturned Containers"}
          amount={data.overdueContainers}
          tooltip={"Containers assigned and now passed the due date"}
        />
      </div>
      <div className={styles.row}>
        {data.containerTypes?.map((type) => {
          return (
            <ContainerBox
              key={type?.name}
              name={type.name}
              amount={type.amount}
              iconURL={type.iconURL}
              type="small"
            />
          );
        })}
      </div>
    </div>
  );
};

export default ContainerStats;
