import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../../_store/actions/main.actions";
import ListPurchased from "../list/listPurchased";
import styles from "../containerDetails.module.scss";
import t from "../../../../../_config/constants";
import FormModal from "../../../../modal/formModal";
import RestaurantSelect from "../borrowed/restaurantSelect";
const PurchasedList = ({
  id,
  selectedContainers,
  handleUpdate,
  handleNoSelectedContainers,
  update,
  handleCheckedContainers,
}) => {
  const [currentData, setCurrentData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const getCurrentData = async (id) => {
    let filter = [];
    if (id) {
      filter.push({
        state: { $in: ["purchased", "purchase_failed"] },
        user_id: { $eq: id },
      });
    }
    let response = await dispatch(
      mainActions.run("data", "container", "all", { filter })
    );
    setCurrentData(response);
    setLoadingData(false);
  };
  useEffect(() => {
    if (id) {
      getCurrentData(id);
    }
  }, [id, update]);
  const closeModal = () => {
    setShowModal(false);
    getCurrentData(id);
    handleUpdate();
    handleNoSelectedContainers();
  };

  const openModal = () => {
    setShowModal(true);
  };
  return (
    <>
      {showModal && (
        <FormModal
          show={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
          content={
            <RestaurantSelect
              containers={selectedContainers}
              handleClose={closeModal}
            />
          }
          size="md"
        />
      )}
      <div className={styles.options}>
        {" "}
        <p
          className={styles.retire}
          style={
            selectedContainers.length > 0
              ? { cursor: "pointer" }
              : { cursor: "not-allowed" }
          }
          onClick={selectedContainers.length > 0 ? openModal : null}
        >
          {t.button.text_return}
        </p>
      </div>
      <ListPurchased
        data={currentData}
        loadingData={loadingData}
        selectedContainers={selectedContainers}
        handleCheckedContainers={handleCheckedContainers}
      />
    </>
  );
};
export default PurchasedList;
